import React from 'react';
import { Modal, Input, InputNumber } from 'antd';

import styles from './index.module.css';
import { CreateOrderItemComponent } from '../../../apollo';
import { withRouter } from 'react-router';
import { message } from '../../../components/message';

class AddPriceModal extends React.Component<any> {
  state = {
    visible: false,
    detail: '',
    price: 1,
  };
  render() {
    return (
      <div style={{ display: 'inline-block' }}>
        <span
          style={{ color: 'rgb(36, 131, 248)' }}
          className="hand"
          onClick={() => this.setState({ visible: true })}
        >
          {this.props.text || '新增优惠'}
        </span>
        <CreateOrderItemComponent>
          {send => {
            return (
              <Modal
                visible={this.state.visible}
                // className={`modal-cover modal-cover-body`}
                width={600}
                // footer={null}
                title={'新增优惠'}
                closable={true}
                okText="确定"
                cancelText="取消"
                maskClosable={true}
                onCancel={() => this.setState({ visible: false })}
                onOk={async () => {
                  const { detail, price } = this.state;
                  if (!detail) {
                    return message.error(new Error('请输入明细'));
                  }
                  if (!price) {
                    return message.error(new Error('请输入金额'));
                  }
                  await send({
                    variables: {
                      CreateOrderItem: {
                        type: this.props.order_type === 1 ? '价格调整' : '优惠',
                        detail,
                        price,
                        order_type: this.props.order_type,
                        out_trade_no: this.props.out_trade_no,
                      },
                    },
                  });
                  message.success('操作成功');
                  if (this.props.reFetch) {
                    this.props.reFetch();
                  }
                  this.setState({
                    visible: false,
                  });
                }}
              >
                <div className={styles.modal_container}>
                  {/* <span style={{ fontSize: '18px' }}>新增优惠</span> */}
                  <div style={{ textAlign: 'left' }}>
                    <span style={{ paddingRight: '20px' }}>明细</span>
                    <Input
                      // className="king_normal_text_area"
                      placeholder="请填写价格调整明细，不超过12个字"
                      onChange={e => this.setState({ detail: e.target.value })}
                      maxLength={30}
                      style={{ width: '50%' }}
                    />
                    <br />
                    <br />
                    <br />
                    <span style={{ paddingRight: '20px' }}>金额</span>
                    <span style={{ paddingRight: '12px' }}>
                      {this.props.order_type === 1 ? '+' : '-'}
                    </span>
                    <InputNumber
                      min={1}
                      max={10000}
                      placeholder="金额"
                      onChange={price => this.setState({ price })}
                    />
                    {this.props.order_type === 0 ? (
                      <div style={{ textAlign: 'right' }}>
                        新增后实付⾦额
                        <span
                          style={{
                            fontSize: '18px',
                            fontWeight: 600,
                            paddingLeft: '12px',
                          }}
                        >
                          ¥ {this.props.price - this.state.price}
                        </span>
                      </div>
                    ) : (
                      <div style={{ textAlign: 'right' }}>
                        新增后实付⾦额
                        <span
                          style={{
                            fontSize: '18px',
                            fontWeight: 600,
                            paddingLeft: '12px',
                          }}
                        >
                          ¥ {this.props.price + this.state.price}
                        </span>
                      </div>
                    )}
                  </div>
                  {/* <Button
                    className={`linear-gradient-gray ${styles.sure} normal_button`}
                    onClick={async () => {
                      if (!this.state.reason) {
                        return message.error(new Error('请输入原因'));
                      }
                      await send({
                        variables: {
                          AddPrice: {
                            order_id: this.props.id,
                            reason: this.state.reason,
                          },
                        },
                      });
                      message.success('操作成功');
                      if (this.props.reFetch) {
                        this.props.reFetch();
                      }
                      this.setState({
                        visible: false,
                      });
                    }}
                    type="primary"
                  >
                    确定
                  </Button> */}
                </div>
              </Modal>
            );
          }}
        </CreateOrderItemComponent>
      </div>
    );
  }
}

export default withRouter(AddPriceModal);
