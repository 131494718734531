import React from 'react';

import {
  BaseTable,
} from '../../components/base-table';
import { DesignerDemandsComponent, DemandsCountComponent, OwnNoticesComponent, ReadNoticeComponent } from '../../apollo';
import { AllTable } from '../../components/base-table';
import { HeaderLayout } from '../../layout/header-layout';
import { columns } from './demand.columns';
import { Tabs, Alert } from 'antd';
import styles from './index.module.css';
import moment from 'moment';
import { Link } from 'react-router-dom';

const { TabPane } = Tabs;

const tabarList = [
  { key: '1', value: 'ui进行中' },
  { key: '2', value: '已完成' },
  { key: '3', value: '已取消' },
]

function getData(data: any) {
  const name = 'designerDemands';
  const new_data =
    data[name] && data[name].list
      ? data[name].list.map((item: any) => {
        return {
          ...item,
          key: item.id,
          status: item.status,
          name: item.name,
          created_date: item.created_date,
          end_date: item.end_date,
          type: item.project.title,
          owner_username: item.owner.username,
          designer_username: item.designer && item.designer.username,
          company_name: item.team.name,
        };
      })
      : [];
  const count = (data[name] && data[name].count) || 0;
  return {
    data: new_data,
    count,
  };
}

const columns_titles = columns().map(({ title }) => title);

const base_titles = columns_titles;

class DemandTable extends BaseTable {
  state = {
    checked_options: [...base_titles, '完成时间'],
    query_params: {},
    fixed: false,
  };

  onCheckAllChange = (e: any) => {
    this.setState({
      checked_options: e.target.checked ? columns_titles : [],
    });
  };

  onColumnChange = (checkedList: any) => {
    this.setState({
      checked_options: checkedList,
    });
  };

  render() {
    // const params = queryString.parse(this.props.location.search);
    // const skip = Number(params.skip) || 0;
    const { status } = this.props;
    return (
      <DesignerDemandsComponent
        variables={{
          // skip,
          take: 20,
          status,
          ...this.state.query_params,
        }}
        fetchPolicy="no-cache"
      >
        {({ data, error, refetch }) => {
          if (error) {
            console.log('error', error);
            return <>Access denied</>;
          }
          const result = getData(data);
          let new_columns: any = columns(refetch).map((item) => {
            if (item.key === 'finish_date' && status === '已完成') {
              return {
                ...item,
                title: '完成时间',
              };
            }
            return item;
          });
          new_columns = new_columns.filter((item: any) => {
            if (status === '已取消' && item.key === 'finish_date') {
              return false;
            }
            if (
              (status === '已取消' || status === '已完成') &&
              item.key === 'action'
            ) {
              return false;
            }
            return item;
          });
          return (
            <div className="table_container">
              <AllTable
                scroll={{ x: true }}
                onChange={(pagination: any, filters: any, sorter: any) => {
                  this.onTableChange(pagination, filters, sorter, refetch)
                }
                }
                columns={new_columns}
                loading={data && data['designerDemands'] ? false : true}
                dataSource={result.data}
                pagination={{
                  total: result.count,
                  pageSize: 20,
                }}
                checked_options={this.state.checked_options}
                fixed={this.state.fixed}
              />
            </div>
          );
        }}
      </DesignerDemandsComponent>
    );
  }
}

export default class Demand extends React.Component<any> {
  state = {
    activeKey: '1',
    panes: tabarList,
    status: 'ui进行中'
  }
  async read(id: string, send: any, refetch: any) {
    await send({
      variables: {
        FinishAnnex: {
          id
        }
      },
    });
    refetch();
  }
  onChange = (key: string, refetch: any) => {
    // refetch()
    this.state.panes.forEach(item => {
      if (item.key === key) {
        this.setState({ activeKey: key, status: item.value })
      }
    })
  }
  render() {
    return (
      <HeaderLayout>
        <DemandsCountComponent>
          {({ data, loading, error, refetch }) => {
            if (!data || loading || error) {
              return '';
            }
            const new_data: any = data;
            return (
              <div style={{ position: 'relative' }}>
                <div className={styles.all_title}>工作总览</div>
                <div className={styles.number_container}>
                  <div>
                    <div style={{ fontSize: "60px", fontWeight: 600 }}>{new_data.finish_count.count}</div>
                    <div>已完成需求</div>
                  </div>
                  <div style={{ marginLeft: "100px" }}>
                    <div style={{ fontSize: "60px", fontWeight: 600 }}>{new_data.ing_count.count}</div>
                    <div>待处理需求</div>
                  </div>
                  <div style={{ marginLeft: "100px" }}>
                    <div style={{ fontSize: "60px", fontWeight: 600 }}>{new_data.designerTeamList.count}</div>
                    <div>当前客户数</div>
                  </div>
                </div>
                <div style={{ marginLeft: "60px", position: "absolute", height: "260px", overflowY: "auto", right: 0, top: 80 }}>
                  <div style={{ fontSize: "30px", fontWeight: 600 }}>客户明细</div>
                  <br />
                  {
                    new_data.designerTeamList.list.map((team: any) => {
                      return <div key={team.id} style={{ marginLeft: "20px" }}>
                        <span style={{ fontSize: '18px', color: "#69707A", display: "inline-block", width: "300px" }}><Link to={`/company?id=${team.id}`}>{team.name}</Link></span>
                        <span>
                          {team.plan ? <span style={{
                            color: "#7CB2B8",
                          }}>
                            {team.plan} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 订阅至{moment(team.plan_end_date).format("YYYY年MM月DD日")}
                          </span> : <span style={{
                            color: "#AEB1B5"
                          }}>暂无订阅</span>}
                        </span>
                      </div>
                    })
                  }
                </div>
                <br />
                <br />
                <br />
                <br />
                <div className={styles.all_title}>需求列表</div>
                <br />
                <OwnNoticesComponent>
                  {({ data, loading, error, refetch }) => {
                    if (!data || loading || error) {
                      return '';
                    }
                    return data.ownNotices.map((item) => {
                      return (
                        <ReadNoticeComponent>
                          {(send) => {
                            return <Alert style={{ marginTop: "6px" }} message={item.message} type="warning" showIcon closable onClose={() => this.read(item.id, send, refetch)} />
                          }}
                        </ReadNoticeComponent>
                      )
                    })
                  }}
                </OwnNoticesComponent>
                <div style={{ position: 'relative' }}>
                  <Tabs
                    onChange={(key) => this.onChange(key, refetch)}
                    activeKey={this.state.activeKey}
                  >
                    {this.state.panes.map(pane => (
                      <TabPane
                        tab={
                          pane.key === '1' ?
                            `进行中(${new_data.ing_count.count})`
                            :
                            pane.key === '2'
                              ?
                              `已完成(${new_data.finish_count.count})`
                              :
                              `已取消(${new_data.cancel_count.count})`
                        }
                        key={pane.key}
                      >
                        <DemandTable status={this.state.status} />
                      </TabPane>
                    ))}
                    {/* <TabPane
                      tab={`进行中(${new_data.ing_count.count})`}
                      key="1"
                    >
                      <DemandTable status="ui进行中" />
                    </TabPane>
                    <TabPane
                      tab={`已提交(${new_data.wait_count.count})`}
                      key="2"
                    >
                      <DemandTable status="等待中" />
                    </TabPane>
                    <TabPane
                      tab={`已完成(${new_data.finish_count.count})`}
                      key="3"

                    >
                      <DemandTable status="已完成" />
                    </TabPane>
                    <TabPane
                      tab={`已取消(${new_data.cancel_count.count})`}
                      key="4"
                    >
                      <DemandTable status="已取消" />
                    </TabPane> */}
                  </Tabs>
                </div>
              </div>);
          }}
        </DemandsCountComponent>
      </HeaderLayout>
    );
  }
}
