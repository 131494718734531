import React from 'react';

import './login.css';
import styles from './login.module.css';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

class LoginBack extends React.Component<any, any> {
  componentDidMount () {
    const params = queryString.parse(window.location.search);
    sessionStorage.session = JSON.stringify(params);
    sessionStorage.token = params.access_token;
    // localStorage.session = JSON.stringify(params);
    // localStorage.token = params.access_token;
    this.props.history.push('/');
  }

  render() {
    return (
      <div className={styles.container}>
      
      </div>
    );
  }
}

export default withRouter(LoginBack);
