const windows_uri = window.location.origin;

let dd_uri = windows_uri || 'https://platform.nef.design';

if (
  window.location.hostname === '127.0.0.1' ||
  window.location.hostname === 'localhost'
) {
  // dd_uri = 'https://designer.test.nef.design';
  dd_uri = 'http://127.0.0.1:9999';
}

export const server_uri = dd_uri;

export const oss_uri = `https://nef-design-public.oss-cn-beijing.aliyuncs.com/`

export const graphql_uri = `${server_uri}/graphql`;

export const app_id = "ww4f00b0b838e730ed";
// export const app_id = "ww98536a44b85e2586";
// export const app_id = "wwcfd927b9e7400acf";
