import React from 'react';
import { Layout, Menu } from 'antd';
import parse from 'url-parse';
import queryString from 'query-string';

import styles from './header-layout.module.css';
import { withRouter } from 'react-router';
import {
  ViewerComponent,
} from '../apollo';
import { message } from '../components/message';
import { Link } from 'react-router-dom';

const { Header, Content } = Layout;

interface props {
  location: Location;
  history: any;
}

class HeaderLayoutComponent extends React.Component<any> {
  state = {
    collapsed: false,
    visible: false,
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  onClick = ({ key }: { key: string }) => {
    const url: any = parse(key);
    const params = queryString.parse(url.query);
    const params2 = queryString.parse(this.props.location.search);
    const params3 = queryString.stringify({
      ...params2,
      ...params,
    });
    this.props.history.push(url.pathname + '?' + params3);
  };

  logout = () => {
    sessionStorage.removeItem('token');
    // localStorage.removeItem('token');
    this.props.history.push('/login');
  };

  out = () => {
    this.setState({
      visible: true,
    });
  };

  outTeam = async (send: any) => {
    try {
      await send();
      message.success('退出成功');
      // this.props.history.push('/service');
      setTimeout(() => {
        window.location.reload();
      }, 600);
    } catch (e) {
      message.error(e);
    } finally {
      this.setState({
        visible: true,
      });
    }
  };

  render() {
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <div style={{ height: '64px' }} />
        <Header className={styles.header}>
          <ViewerComponent>
            {({ data, error, loading }) => {
              if (loading || !data || error) {
                return '';
              }
              const default_key = this.props.location.pathname || '/service';
              return (
                <div className={styles.menu_container}>
                  <Menu
                    mode="horizontal"
                    className={styles.menu}
                    defaultSelectedKeys={[default_key]}
                  >
                    <Menu.Item
                      className={
                        default_key === '/demand'
                          ? styles.menu_item_active
                          : styles.menu_item
                      }
                      key="/demand"
                    >
                      <Link to="/demand">
                        <span
                          className={
                            default_key === '/demand'
                              ? styles.menu_item_active_left
                              : styles.menu_item_left
                          }
                        />
                        <span
                          className={
                            default_key === '/demand'
                              ? styles.menu_item_active_color
                              : styles.menu_item_color
                          }
                        >
                          工作台
                        </span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item
                      className={
                        default_key === '/demands'
                          ? styles.menu_item_active
                          : styles.menu_item
                      }
                      key="/demands"
                    >
                      {/* <Link to="https://shimo.im/docs/PydyqKvHqvTr8cJv"> */}
                        <a href="https://shimo.im/docs/PydyqKvHqvTr8cJv">
                          资源库
                          {/* <span
                          className={
                            default_key === '/demands'
                              ? styles.menu_item_active_left
                              : styles.menu_item_left
                          }
                        />
                        <span
                          className={
                            default_key === '/demands'
                              ? styles.menu_item_active_color
                              : styles.menu_item_color
                          }
                        >
                          资源库
                        </span> */}
                        </a>
                      {/* </Link> */}
                    </Menu.Item>
                    {/* <Menu.Item
                      className={
                        default_key === '/order'
                          ? styles.menu_item_active
                          : styles.menu_item
                      }
                      key="/order"
                    >
                      <Link to="/order">
                        <span
                          className={
                            default_key === '/order'
                              ? styles.menu_item_active_left
                              : styles.menu_item_left
                          }
                        />
                        <span
                          className={
                            default_key === '/order'
                              ? styles.menu_item_active_color
                              : styles.menu_item_color
                          }
                        >
                          结算中心
                        </span>
                      </Link>
                    </Menu.Item> */}
                  </Menu>
                  {/* <Popover
                    content={
                      <div onClick={() => this.logout()} className={`hand`}>
                        登 出
                        </div>
                    }
                    placement="bottomRight"
                    title={null}
                    trigger="click"
                  > */}
                    <div
                      className={`${styles.header_img} header_img_container hand`}
                    >
                      <img
                        className="header_img"
                        src={data.viewer.avatar || ''}
                        alt=""
                      />
                      {/* <Icon className={styles.down} type="down" /> */}
                    </div>
                  {/* </Popover> */}
                </div>
              );
            }}
          </ViewerComponent>
        </Header>
        <Content className={styles.content} style={this.props.contentStyle}>
          {this.props.children}
        </Content>
      </Layout>
    );
  }
}

export const HeaderLayout = withRouter(HeaderLayoutComponent);
