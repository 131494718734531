import React from 'react';

import queryString from 'query-string';
import { BaseTable } from '../../components/base-table';
import { OrdersComponent } from '../../apollo';
import { AllTable } from '../../components/base-table';
import { columns } from './order.columns';
import { withRouter } from 'react-router';
import { BaseLayout } from '../../layout/base-layout';
// import styles from './index.module.css';

function getData(data: any) {
  const name = 'orders';
  const new_data =
    data[name] && data[name].list
      ? data[name].list.map((item: any) => {
          let children = null;
          if (item.children.length > 0) {
            children = item.children;
          }
          return {
            ...item,
            key: item.id,
            username: item.owner.username,
            children,
          };
        })
      : [];
  const count = (data[name] && data[name].count) || 0;
  return {
    data: new_data,
    count,
  };
}

const columns_titles = columns().map(({ title }) => title);

const base_titles = columns_titles;

class OrderTable extends BaseTable {
  state = {
    checked_options: base_titles,
    query_params: {},
    fixed: false,
    visible: false,
  };

  onCheckAllChange = (e: any) => {
    this.setState({
      checked_options: e.target.checked ? columns_titles : [],
    });
  };

  onColumnChange = (checkedList: any) => {
    this.setState({
      checked_options: checkedList,
    });
  };

  render() {
    const params = queryString.parse(this.props.location.search);
    const skip = Number(params.skip) || 0;
    return (
      <BaseLayout>
        <OrdersComponent
          variables={{
            skip,
            take: 20,
            // team_id: String(params.id),
            ...this.state.query_params,
          }}
          fetchPolicy="no-cache"
        >
          {({ data, error, refetch }) => {
            if (error) {
              console.log('error', error);
              return <>Access denied</>;
            }
            const result = getData(data);
            return (
              <div className="table_container">
                {/* <TableTitle name={`用户管理`} number={result.count} /> */}
                {/* <TableSearch
                search={(e: any) => this.search(e.target.value)}
                options={columns_titles}
                checked_options={this.state.checked_options}
                onCheckAllChange={this.onCheckAllChange}
                onColumnChange={this.onColumnChange}
                outData={() => {}}
                columns={this.state.checked_options}
                fixed={this.state.fixed}
                fixedName={(fixed: any) => {
                  this.setState({ fixed });
                }}
                params={{
                  skip,
                  take: 20,
                  ...this.state.query_params,
                }}
              /> */}
                <AllTable
                  bordered={false}
                  scroll={{ x: true }}
                  onChange={(pagination: any, filters: any, sorter: any) =>
                    this.onTableChange(pagination, filters, sorter, refetch)
                  }
                  columns={columns(refetch)}
                  loading={data && data['orders'] ? false : true}
                  dataSource={result.data}
                  pagination={{
                    total: result.count,
                    pageSize: 20,
                  }}
                  defaultExpandAllRows={true}
                  checked_options={this.state.checked_options}
                  fixed={this.state.fixed}
                />
              </div>
            );
          }}
        </OrdersComponent>
      </BaseLayout>
    );
  }
}

export const Order = withRouter(OrderTable);
