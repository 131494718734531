import React from 'react';
import { Button, Modal, Form, Input, Icon, Upload } from 'antd';

import { UpdateDemandMoreComponent } from '../../../apollo';
import { message } from '../../../components/message';
import styles from './index.module.css';
import { up_demand_more_options } from '../../../lib/upload';
import { getFilesName } from '../../../lib';
// import moment from 'moment';

const { TextArea } = Input;

class ChangeMoreDemandModal extends React.Component<any> {
  state = {
    visible: false,
    uri: '',
    uris: [],
  };

  handleSubmit = (send: any, e: any) => {
    e.preventDefault();
    this.props.form.validateFields(async (err: any, values: any) => {
      if (!err) {
        try {
          let uris = this.props.more.uris;
          if (this.state.uris.length > 0) {
            uris = getFilesName(this.state.uris, values.uris);
          }
          await send({
            variables: {
              UpdateDemandMore: { id: this.props.more.id, ...values, uris: uris || undefined },
            },
          });
          message.success('修改成功');
          const { refetch } = this.props;
          refetch();
          this.close();
        } catch (e) {
          message.error(e);
        }
      }
    });
  };

  close = () => {
    const { resetFields } = this.props.form;
    resetFields();
    this.setState({ visible: false, uri: '' });
  };

  render() {
    const { visible } = this.state;
    const { form } = this.props;
    const { getFieldDecorator, getFieldValue } = form;
    const uploadButton = (
      <div>
        <Button className="btn_upload">
          <Icon type="plus" />
          {/* <div className="ant-upload-text">上传</div> */}
          上传文件
        </Button>
        {/* <span className="file_desc">
          支持pdf及word，jpg格式，.zip 和 .rar格式，.ppt 和 .pptx
        </span> */}
      </div>
    );
    return (
      <div>
        <Button
          className={`linear-gradient-gray ${styles.sure}`}
          onClick={() => this.setState({ visible: true })}
          type="primary"
        >
          修改补充需求
        </Button>
        <Modal
          title="修改补充需求"
          centered
          visible={visible}
          onCancel={() => this.setState({ visible: false })}
          footer={null}
        >
          <Form>
            <Form.Item label="注意补充的需求信息客户也可以看到，注意措辞">
              {getFieldDecorator('context', {
                rules: [{ required: true, message: '请输入备注!' }],
                initialValue: this.props.more.context
              })(
                <TextArea
                  rows={4}
                  maxLength={200}
                  className="king_normal_text_area"
                />,
              )}
              <span className="form_right">
                {(getFieldValue('context') &&
                  getFieldValue('context').length) ||
                  0}
                /200
              </span>
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('uris')(
                <Upload
                  {...up_demand_more_options((uri: string, file: any) => {
                    this.setState({
                      uris: [...this.state.uris, { [uri]: file.name }],
                    })
                  })}
                  accept=".pdf,.jpg,.docx,.doc,.rar,.zip,.ppt,.pptx"
                  defaultFileList={
                    getFieldValue('uris') &&
                    getFieldValue('uris').fileList
                  }
                >
                  {((getFieldValue('uris') &&
                    getFieldValue('uris').fileList
                      .length < 5) ||
                    !getFieldValue('uris')) &&
                    uploadButton}
                </Upload>,
              )}
            </Form.Item>
            <Form.Item style={{ textAlign: 'right' }}>
              <Button
                onClick={() => this.setState({ visible: false })}
                style={{ marginRight: '30px' }}
              >
                取消
              </Button>
              <UpdateDemandMoreComponent>
                {(send, { loading }) => {
                  return (
                    <Button
                      type="primary"
                      loading={loading}
                      onClick={this.handleSubmit.bind(this, send)}
                    >
                      确定
                    </Button>
                  );
                }}
              </UpdateDemandMoreComponent>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

export const ChangeMoreDemandModalForm: any = Form.create({
  name: 'create_car',
})(ChangeMoreDemandModal);
