import React from 'react';

import queryString from 'query-string';
import { HeaderLayout } from '../../layout/header-layout';
import { Tabs } from 'antd';
import { Member } from './member';
import { GetTeamByIdComponent } from '../../apollo';

const { TabPane } = Tabs;

export default class Company extends React.Component {
  state = {
    query_params: {},
  };

  render() {
    const params = queryString.parse(window.location.search);
    // const skip = Number(params.skip) || 0;
    return (
      <GetTeamByIdComponent variables={{ id: String(params.id) }}>
        {({ data, loading, error, refetch }) => {
          if (!data || loading || error) {
            return '';
          }
          return (
            <HeaderLayout>
              <Tabs defaultActiveKey="1">
                <TabPane tab="公司成员" key="1">
                  <Member owner_id={data.getTeamById.owner.id} />
                </TabPane>
              </Tabs>
            </HeaderLayout>
          );
        }}
      </GetTeamByIdComponent>
    );
  }
}
