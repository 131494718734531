import React from 'react';
import styles from './index.module.css';
import { HeaderLayout } from '../../layout/header-layout';
import { Row, Col, Button } from 'antd';
import one from './one@2x.png';
import two from './two@2x.png';
// import three from './three@2x.png';
import four from './four@2x.png';
import five from './five@2x.png';
import six from './six@2x.png';
import { Link } from 'react-router-dom';
import { QrCodeText } from '../choose-type-new/qr_code_text';
import queryString from 'query-string';


interface DemandTypeCardProps {
  src: string;
  title: string;
  desc: any;
  to: string;
}

class DemandTypeCard extends React.Component<DemandTypeCardProps> {
  render() {
    return (
      <div className={styles.card_container}>
        <img alt="" className={styles.card_img} src={this.props.src} />
        <div className={styles.card_title}>{this.props.title}</div>
        <div className={styles.card_desc}>{this.props.desc}</div>
        {this.props.to.includes('http') ? (
          <a href={this.props.to} target="view_window">
            <Button className={styles.card_button}>下一步</Button>
          </a>
        ) : (
          <Link to={this.props.to}>
            <Button className={styles.card_button}>下一步</Button>
          </Link>
        )}
      </div>
    );
  }
}

class ChooseDemandType extends React.Component<any> {
  render() {
    const params = queryString.parse(window.location.search);
    return (
      <HeaderLayout>
        <div className={styles.container}>
          <div style={{ position: 'relative' }}>
            <div className={styles.title}>创建新的设计需求</div>
            <div className={styles.desc}>
              选择该设计类型应用的场景。如需帮助，请
              {/* <span style={{ color: '#DEBB6E' }}>联系设计顾问</span> */}
              <QrCodeText />
            </div>
            <a href="http://yun.nef.design/781600.html" className={styles.right_desc}>云设计部的服务范围</a>
          </div>
          <Row
            type="flex"
            justify="start"
            gutter={24}
            style={{ marginTop: '30px' }}
          >
            <Col span={8} style={{ position: 'relative' }}>
              <DemandTypeCard
                src={one}
                title="屏幕显示"
                desc={
                  <div>
                    - 用于微信推文、朋友圈、社群的海报、长图文 <br />
                    - 小鹅通、有赞通等平台的产品详情介绍长图 <br />-
                    用户手机及电脑阅读的PDF 文档、PPT 等
                  </div>
                }
                to={`/choose-type?name=屏幕展示&user_id=${params.user_id}&team_id=${params.team_id}`}
              />
            </Col>
            <Col span={8} style={{ position: 'relative' }}>
              <DemandTypeCard
                src={five}
                title="印刷及线下"
                desc={
                  <div>
                    - 用于线下传播的海报、易拉宝、传单、折页等 <br />
                    - 手册类，如企业宣传册、产品手册等 <br />
                    - 活动类如 背景板、展板、展架、背景墙等 <br />-
                    其他需印制的物料如 卡/票/券/的等
                  </div>
                }
                to={`/choose-type?name=印刷或线下物料&user_id=${params.user_id}&team_id=${params.team_id}`}
              />
            </Col>
            <Col span={8} style={{ position: 'relative' }}>
              <DemandTypeCard
                src={six}
                title="Logo 及 VIS 设计"
                desc={
                  <div>
                    - logo 图标、标准字 <br />
                    - 设计完整企业视觉识别系统 VIS <br />-
                    根据VIS进行延展，如名片、文化衫、桌牌、杯、笔等
                  </div>
                }
                to={`/choose-type?name=品牌设计&user_id=${params.user_id}&team_id=${params.team_id}`}
              />
            </Col>
          </Row>
          <Row
            type="flex"
            justify="start"
            gutter={24}
            style={{ marginTop: '30px' }}
          >
            <Col span={8} style={{ position: 'relative' }}>
              <DemandTypeCard
                src={two}
                title="UI / UX 用户体验类"
                desc={
                  <div>
                    - APP、小程序、PC网站、移动端网站界面设计 <br />-
                    交互设计、流程设计、信息架构设计
                  </div>
                }
                // to="https://jinshuju.net/f/qNPOYw"
                to={`/create-demand-ui?type=UI&user_id=${params.user_id}&team_id=${params.team_id}`}
              />
            </Col>
            {/* <Col span={8} style={{ position: 'relative' }}>
              <DemandTypeCard
                src={three}
                title="用户研究类"
                desc={
                  <div>
                    - 用户研究策划与执行 <br />
                    - 可用性测试 <br />- UX Benchmarking
                  </div>
                }
                to="https://jinshuju.net/f/otrA6f"
              />
            </Col> */}
            <Col span={8} style={{ position: 'relative' }}>
              <DemandTypeCard
                src={four}
                title="官网 / 落地页 / H5 活动页"
                desc={
                  <div>
                    - 用于微信推文、朋友圈、社群的海报、长图文 <br />
                    - 小鹅通、有赞通等平台的产品详情介绍长图 <br />-
                    用户手机及电脑阅读的PDF 文档、PPT 等
                  </div>
                }
                // to="https://jinshuju.net/f/qa5vZc"
                to={`/create-demand-ui?type=H5&user_id=${params.user_id}&team_id=${params.team_id}`}
              />
            </Col>
          </Row>
        </div>
      </HeaderLayout>
    );
  }
}

export default ChooseDemandType;
