import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { ViewerComponent } from '../apollo';
import { server_uri, app_id } from '../config';

const time = 1000 * 60 * 60 * 24; // 最大登录有效时间为 24 小时

const REDIRECT_URI = encodeURIComponent(`${server_uri}/api/we-chat/enterprise/login/back`)
export class PrivateRoute extends Component<any, {}> {
  auth = {
    status: false,
    updated_date: Date.now(),
  };
  render() {
    const { component: TrueComponent, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={props => {
          if (this.auth.status && Date.now() - this.auth.updated_date < time) {
            return (
              <Route
                {...this.props}
                render={props => <TrueComponent {...props} />}
              />
            );
          }
          return (
            <ViewerComponent>
              {({ data, error, loading }) => {
                if (loading) {
                  return '';
                }
                console.log("data", data)
                // fix 如果是未登录错误 返回登录
                // 如果是其他的错误返回 500 页面
                // 如果是网络错误返回到网络错误页面
                if (error) {
                  // return (
                  //   <Redirect
                  //     exact
                  //     to={{
                  //       pathname: '/login',
                  //       state: { from: this.props.location },
                  //     }}
                  //   />
                  // );
                  // return window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${app_id}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=snsapi_privateinfo&state=STATE#wechat_redirect`
                  window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${app_id}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`
                  return <div></div>
                }
                this.auth = {
                  status: true,
                  updated_date: Date.now(),
                };
                return (
                  <Route
                    {...this.props}
                    render={props => <TrueComponent {...props} />}
                  />
                );
              }}
            </ViewerComponent>
          );
        }}
      />
    );
  }
}
