import React from 'react';
import { HeaderLayout } from '../../layout/header-layout';
import styles from './index.module.css';
import { Modal, Icon, Input, Tag } from 'antd';
import tip from './tip@2x.png';
import {
  GetDemandByIdComponent,
  AnnexGroupListComponent
} from '../../apollo';
import queryString from 'query-string';
import moment from 'moment';
import { getFileName, getFileNameByPath, getTrueName } from '../../lib';
import { DemandChat } from './chat';
import { FinishDemandModalForm } from './finish-demand-modal-form';
import { Tabs } from 'antd';
import { UploadImageModalForm } from './upload-image';
import cat from './cat@2x.png'
import download from './download@2x.png'
import { Link } from 'react-router-dom';
import group from './group@2x.png'
import { getFileType, file_types } from '../photo'
import { MoreDemandModalForm } from './more-demand-modal-form';
import { ChangeMoreDemandModalForm } from './change-more-demand-modal-form';

const { TabPane } = Tabs;

interface OwnFileCardProps {
  uri: string;
  name: string;
}
class OwnFileCard extends React.Component<OwnFileCardProps> {
  render() {
    const { uri, name } = this.props;
    return (
      <a
        target="view_window"
        href={uri}
        style={{ color: '#7CB2B8', marginRight: '28px' }}
      >
        <Icon type="paper-clip" /> {getTrueName(name).replace('files/', '')}
      </a>
    );
  }
}

interface FileCardProps {
  uri: string;
  is_new?: boolean | null | undefined;
  onClick?: any;
  to?: string;
  file_old_name?: string;
}
export class FileCard extends React.Component<FileCardProps> {
  state = {
    visible: false
  }
  render() {
    const { uri, is_new, file_old_name } = this.props;
    return (
      <div className={styles.file_card} onClick={this.props.onClick}>
        {is_new && <div style={{
          position: "absolute",
          right: "2px",
          top: "2px"
        }} className="red_point"></div>}
        <Modal
          visible={this.state.visible}
          className={`modal-cover modal-cover-body`}
          width={600}
          footer={null}
          title={null}
          onCancel={() => this.setState({ visible: false })}
          closable={true}
          maskClosable={true}
        >
          <img src={file_types.includes(getFileType(uri)) ? uri : group} alt="" />
        </Modal>
        {/* <img alt="错误" src={uri} /> */}
        <img src={file_types.includes(getFileType(uri)) ? uri : group} alt="" />
        <div>
          <div className={styles.file_title}>{file_old_name ? getFileNameByPath(file_old_name) : getFileNameByPath(uri)}</div>
          {/* <div className={styles.file_mb}>1.2 MB</div> */}
        </div>
        <div className={styles.file_card_icon}>
          {this.props.to ? <Link to={this.props.to}><img alt="" src={cat} /></Link> : <img alt="" src={cat} onClick={() => this.setState({ visible: true })} />}
          <a download="download.jpg" target="_blank" rel="noopener noreferrer" href={uri}>
            <img alt="" src={download} />
          </a>
        </div>
      </div>
    );
  }
}


interface RightDetailProps {
  title: string;
  content?: string | null;
}

class RightDetail extends React.Component<RightDetailProps> {
  render() {
    const { title, content } = this.props;
    return (
      <>
        {/* <div className={styles.detail_title}>{title}</div> */}
        <ItemTitle name={title} />
        {/* <p className={styles.detail_content}>{content} </p> */}
        <Input.TextArea
          name="note"
          autosize={true}
          disabled
          className={`${styles.note} ${styles.detail_content}`}
        >
          {content}
        </Input.TextArea>
        {/* <textarea name="note" disabled className={`${styles.note} ${styles.detail_content}`}>
          {content}
        </textarea> */}
      </>
    );
  }
}

function StatusTag({ status }: { status: string }) {
  let color = 'geekblue';
  if (status === '等待中') {
    color = 'red';
  }
  if (status === '已完成') {
    color = 'green';
  }
  if (status === '已取消') {
    color = 'gray';
  }
  return <Tag color={color}>{status}</Tag>
}

function ItemTitle({ name }: { name: string }) {
  return <div style={{ marginTop: "6px" }}><span className="status-point" /><span className="little_title">{name}：</span></div>
}

class ChatDetail extends React.Component<any> {
  onRef: any
  // constructor(props) {
  //   super(props);
  //   this.onRef = undefined;
  // }

  sendMessage = (first: any, file_old_name: string) => {
    this.onRef.sendMessage(first, file_old_name)
  }

  render() {
    const search: any = queryString.parse(window.location.search);
    return (
      <HeaderLayout>
        <div className="go_back" onClick={() => this.props.history.goBack()}>
          <Icon type="left-circle" />&nbsp;返回上层
        </div>
        <GetDemandByIdComponent variables={{ id: search.id }}>
          {({ data, error, loading, refetch }) => {
            if (error || loading || !data) {
              return '';
            }
            const demand = data.getDemandById;
            return (
              <div className={styles.container}>
                <div className={styles.left}>
                  <div className={styles.left_first}>
                    <div style={{ position: 'relative' }}>
                      <div>{demand.project.title} <StatusTag status={demand.status} /></div>
                    </div>
                    <div className={styles.left_title}>{demand.name}</div>
                    {(demand.status === '进行中' || demand.status === '等待中') && (
                      <FinishDemandModalForm id={demand.id} refetch={refetch} />
                    )}
                    <Tabs defaultActiveKey="1">
                      <TabPane
                        tab={`需求详情`}
                        key="1"
                      >
                        <ItemTitle name="需求描述" />
                        <div className={styles.left_first_middle}>
                          <div>
                            <div className={styles.user_name}>
                              <img
                                className="header_img_card"
                                src={demand.owner.headimgurl || ''}
                                alt=""
                              />
                              &nbsp;{demand.owner.username}
                              <br />
                              <span style={{ paddingTop: "8px", display: "inline-block" }}>创建于 &nbsp;&nbsp;{moment(demand.created_date).utcOffset(8).format('YYYY-MM-DD HH:mm:ss')}</span>
                            </div>
                          </div>
                        </div>
                        <br />
                        <div>
                          {demand.logo_name ? (
                            <RightDetail
                              title="Logo 需要体现的寓意，包含的要素等"
                              content={demand.description}
                            />
                          ) : (
                            <RightDetail
                              title="需求描述"
                              content={demand.description}
                            />
                          )}
                          {demand.description_files &&
                            demand.description_files.map(({ name, uri }) => {
                              return <OwnFileCard key={uri} name={name} uri={uri} />;
                            })}
                          {demand.description_files && (
                            <>
                              <br />
                              <br />
                            </>
                          )}
                          {demand.client && (
                            <RightDetail
                              title="用户群体"
                              content={demand.client}
                            />
                          )}
                          {demand.client_desc && (
                            <RightDetail
                              title="用户群体描述"
                              content={demand.client_desc}
                            />
                          )}
                          {demand.logo_name && (
                            <RightDetail
                              title="Logo 对应的中英文品牌名称"
                              content={demand.logo_name}
                            />
                          )}
                          {demand.logo_name && (
                            <RightDetail
                              title="Logo 类型"
                              content={demand.logo_type}
                            />
                          )}
                          <RightDetail
                            title="视觉风格描述"
                            content={demand.ui_description}
                          />
                          {demand.ui_description_files &&
                            demand.ui_description_files.map(({ name, uri }) => {
                              return <OwnFileCard key={uri} name={name} uri={uri} />;
                            })}
                          {demand.ui_description_files && (
                            <>
                              <br />
                              <br />
                            </>
                          )}
                          {demand.ui_description_remark && (
                            <RightDetail
                              title="设计风格备注"
                              content={demand.ui_description_remark}
                            />
                          )}
                          {demand.color_description && (<RightDetail
                            title="色彩偏好"
                            content={demand.color_description || '无'}
                          />)}
                          <RightDetail
                            title="尺寸规格"
                            content={demand.size_description || '无特殊要求'}
                          />
                           {demand.size_description_files &&
                            demand.size_description_files.map(({ name, uri }) => {
                              return <OwnFileCard key={uri} name={name} uri={uri} />;
                            })}
                          <RightDetail
                            title="源文件格式要求"
                            content={demand.original_file_format || '无特殊要求'}
                          />
                          <RightDetail
                            title="图片格式要求"
                            content={demand.file_format || '无特殊要求'}
                          />
                          <RightDetail
                            title="其他要求说明与备注"
                            content={demand.remark || '无'}
                          />
                        </div>
                      </TabPane>
                      <TabPane
                        tab={`设计稿`}
                        key="2"
                      >
                        <UploadImageModalForm callback={this.sendMessage} demand_id={search.id} refetch={refetch} />
                        <AnnexGroupListComponent variables={{ id: search.id }}>
                          {({ data, error, loading, refetch }) => {
                            if (error || loading || !data) {
                              return '';
                            }
                            return (<div className={styles.right_container}>
                              {data.annexGroupList.map(({ date, group_id, items }) => {
                                return <><Link to={`/photo?id=${search.id}&group_id=${group_id}`}><ItemTitle name={`${moment(Number(date)).format("YYYY年MM月DD日 HH:mm")}交付`} /></Link>
                                  {/* return <><br /><ItemTitle name={`${moment(Number(date)).format("YYYY年MM月DD日 HH:mm")}交付`} /> */}
                                  {
                                    items.map((item) => {
                                      return <div>
                                        <FileCard file_old_name={item.file_old_name} uri={item.uri} to={`/photo?id=${search.id}&group_id=${group_id}`}>
                                          <a href={item.uri}>下载</a>
                                        </FileCard>
                                        {/* <img src={item.uri} /> */}
                                      </div>
                                    })
                                  }
                                </>
                              })}
                            </div>)
                          }}
                        </AnnexGroupListComponent>
                      </TabPane>
                    </Tabs>
                  </div>
                  {demand.finish_remark && (
                    <div className={styles.left_second}>
                      <div>
                        <img className={styles.tip_img} alt="" src={tip} />
                        交付备注
                      </div>
                      <div className={styles.tip_text}>
                        交付物备注：{demand.finish_remark}
                      </div>
                    </div>
                  )}
                  <div style={{ width: "303px" }}>
                    {!demand.more ?
                      <MoreDemandModalForm id={demand.id} refetch={refetch} /> :
                      <ChangeMoreDemandModalForm id={demand.id} refetch={refetch} more={demand.more} />
                    }

                    {demand.more && <div key={demand.more.id}>
                      <br />
                      <img className={styles.tip_img} alt="" src={tip} />
                      设计师补充
                      <p style={{ marginTop: "6px" }}>{demand.more.context}</p>
                      {demand.more.oss_uris.map(({ uri, oss_uri }) => {
                        return <a download={uri} target="_blank" rel="noopener noreferrer" href={oss_uri}>
                          {getFileName(uri)}
                        </a>
                      })}
                    </div>
                    }
                  </div>
                </div>

                <div className={styles.right}>
                  <DemandChat ref={(ref) => this.onRef = ref} room_id={search.id} history={this.props.history} />
                </div>
              </div>
            );
          }}
        </GetDemandByIdComponent>
      </HeaderLayout>
    );
  }
}


export default ChatDetail;
