import React from 'react';
// import { Tag } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
// import { ChangeDemandTimeModalForm } from './change-demand-time-modal-form';
import { ChangeDemandModalForm } from './change-demand-modal-form';
import { ChangeDemandTimeModalForm } from './change-demand-time-modal-form';

export const columns = (refetch?: any) => [
  {
    title: '需求名称',
    dataIndex: 'name',
    key: 'name',
    width: 120,
    search: true,
    render: (text: string, record: any) => {
      return <Link to={`/detail?id=${record.key}`}>{text}</Link>;
    },
  },
  {
    title: '需求提出人',
    dataIndex: 'owner_username',
    key: 'owner_username',
    search: true,
    width: 120,
  },
  {
    title: '所属客户',
    dataIndex: 'company_name',
    key: 'company_name',
    search: true,
    width: 120,
  },
  {
    title: '需求类型',
    dataIndex: 'type',
    key: 'type',
    width: 120,
    filters: [
      { text: '显示屏幕-海报设计', value: '显示屏幕-海报设计' },
      { text: '印刷及线下', value: '印刷及线下' },
      { text: 'LOGO设计', value: 'LOGO设计' },
      { text: '用户体验类', value: '用户体验类' },
      { text: '用户研究类', value: '用户研究类' },
      { text: 'H5落地页', value: 'H5落地页' },
    ],
  },
  // {
  //   title: '提交时间',
  //   dataIndex: 'created_date',
  //   key: 'created_date',
  //   width: 120,
  //   sorter: true,
  //   render: (text: string, record: any) =>
  //     moment(text).format('YYYY-MM-DD HH:mm'),
  // },
  {
    title: '交付截止时间',
    dataIndex: 'finish_date',
    key: 'finish_date',
    width: 120,
    sorter: true,
    render: (text: string, record: any) =>
    // moment(text).format('YYYY-MM-DD HH:mm'),
    {
      const status = record.status;
      return <div>
        {
          (status === '进行中') && (
            <div >
              预计{moment(text).format('YYYY-MM-DD HH:mm')}前交付
            </div>
          )
        }
        {
          (status === '等待中') && (
            <div >
              需求已确认，等待排期中，当前排序{record.position}
            </div>
          )
        }
        {
          (status === '待确认') && (
            <div>
              待设计师确认需求明细后，反馈交付时间，当前排序{record.position}
            </div>
          )
        }

        {
          status === '已完成' && (
            <div >
              已于{moment(text).format('YYYY-MM-DD HH:mm')}交付
            </div>
          )
        }
        {
          status === '已取消' && (
            <div >
              需求已取消
            </div>
          )
        }
      </div>
    }
  },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
    width: 120,
    render: (status: string, record: any) => (
      <div>
        <ChangeDemandModalForm status={status} id={record.key} finish_date={record.finish_date} refetch={refetch} />
      </div>
    ),
  },
  // {
  //   title: '状态',
  //   dataIndex: 'designer_status',
  //   key: 'designer_status',
  //   fixed: 'left',
  //   width: 80,
  //   filters: [
  //     { text: '已逾期', value: '已逾期' },
  //     { text: '已处理', value: '已处理' },
  //     { text: '待处理', value: '待处理' },
  //   ],
  //   render: (text: string, record: any) => {
  //     let color = 'geekblue';
  //     if (record.designer_status === '已逾期') {
  //       color = 'red';
  //     }
  //     if (record.designer_status === '已处理') {
  //       color = 'green';
  //     }
  //     if (record.designer_status === '待处理') {
  //       color = 'gray';
  //     }
  //     return <Tag color={color}>{text}</Tag>;
  //   },
  // },
  {
    title: '操作',
    width: 160,
    key: 'action',
    render: (_: string, record: any) => (
      <div>
        <Link to={`/demand-chat?id=${record.key}`}>查看详情</Link>
        {record.status === '进行中' ? <ChangeDemandTimeModalForm id={record.key} finish_date={record.finish_date} refetch={refetch} /> : ''}
      </div>
    ),
  },
  {
    title: ' ',
    width: 60,
    key: 'is_new',
    render: (is_new: string, record: any) => {
      return (
        record.is_new && <div className="red_point"></div>
      )
    },
  },
];
