import React from 'react';
import { Modal } from 'antd';

// import styles from './index.module.css';
import { DeleteOrderItemComponent } from '../../../apollo';
import { withRouter } from 'react-router';
import { message } from '../../../components/message';

class DeleteOrderItemModal extends React.Component<any> {
  state = {
    visible: false,
    detail: '',
    price: 1,
  };
  render() {
    return (
      <DeleteOrderItemComponent>
        {send => {
          return (
            <div style={{ display: 'inline-block' }}>
              <span
                style={{ color: 'rgb(36, 131, 248)', fontSize: '22px' }}
                className="hand"
                onClick={() =>
                  Modal.confirm({
                    title: '您确定删除吗？',
                    content: '删除后订单金额将恢复',
                    okText: '确认',
                    cancelText: '取消',
                    onOk: async () => {
                      try {
                        await send({
                          variables: {
                            id: this.props.id,
                          },
                        });
                        message.success('删除成功');
                        const { reFetch } = this.props;
                        reFetch();
                      } catch (e) {
                        message.error(e);
                      }
                    },
                  })
                }
              >
                {this.props.text || '×'}
              </span>
            </div>
          );
        }}
      </DeleteOrderItemComponent>
    );
  }
}

export default withRouter(DeleteOrderItemModal);
