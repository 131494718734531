import React from 'react';
import { Radio, Row, Col, Button } from 'antd';
import queryString from 'query-string';

import styles from './index.module.css';
import { HeaderLayout } from '../../layout/header-layout';
import { projects } from '../../db/product.db';
import { oss_uri } from '../../config';
import { QrCode } from './qr_code';

interface CardProps {
  uri: string;
  title: string;
  content: string;
  description: string;
}

export class Card extends React.Component<CardProps> {
  render() {
    const { uri, title, content, description } = this.props;
    return (
      <div className={styles.card_container}>
        {this.props.children}
        <img className={styles.card_img} alt="" src={oss_uri + uri} />
        <div>
          <div className={styles.card_title}>{title}</div>
          <div className={styles.card_content}>{content}</div>
          <div className={styles.card_description}>{description}</div>
        </div>
      </div>
    );
  }
}

class ChooseType extends React.Component<any> {
  state = {
    value: '',
  };

  submit = (value: string) => {
    const search = queryString.parse(window.location.search);
    if (value === 'Logo图标 / icon / 头像') {
      return this.props.history.push(`/create-demand-special?type=${value}&user_id=${search.user_id}&team_id=${search.team_id}`);
    }
    this.props.history.push(`/create-demand?type=${value}&user_id=${search.user_id}&team_id=${search.team_id}`);
  };

  render() {
    const search = queryString.parse(window.location.search);
    const items = projects[String(search.name)] || [];
    const { value } = this.state;
    const choose_value = value;
    // const choose_value = value || (items[0] && items[0].title);
    return (
      <div style={{ position: 'relative', paddingBottom: '120px' }}>
        <HeaderLayout>
          <div style={{ position: 'relative' }}>
            <div className={styles.title}>创建新的设计需求</div>
            <div className={styles.desc}>选择具体的设计类型</div>
            <QrCode />
          </div>
          <div className={styles.container}>
            <div className={styles.title}>{search.name}</div>
            <div className={styles.main}>
              <Radio.Group
                onChange={e =>
                  this.setState({
                    value: e.target.value,
                  })
                }
                value={choose_value}
              >
                <Row type="flex" justify="start" gutter={128}>
                  {items.map((item: any) => {
                    return (
                      <Col span={12} key={item.title}>
                        <Card {...item}>
                          <Radio className={styles.radio} value={item.title} />
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </Radio.Group>
            </div>
          </div>
        </HeaderLayout>
        <div className={styles.bottom}>
          <div className={styles.bottom_container}>
            <div className={styles.bottom_title}>
              已选择：
              <span style={{ color: '#7CB2B8' }}>
                {search.name}-{choose_value}
              </span>
            </div>
            <div className={styles.bottom_right}>
              <Button
                onClick={() => this.props.history.goBack()}
                className={`${styles.bottom_button} normal_button`}
              >
                上一步
              </Button>
              <Button
                className={`${styles.bottom_button} linear-gradient-gray normal_button`}
                style={{ color: '#FFFFFF' }}
                disabled={!choose_value}
                onClick={this.submit.bind(this, choose_value)}
              >
                下一步
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ChooseType;
