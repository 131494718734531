import React from 'react';
import { Button, Modal, Form, Input } from 'antd';

import { FinishDemandComponent } from '../../../apollo';
import { message } from '../../../components/message';
import styles from './index.module.css';
// import moment from 'moment';

const { TextArea } = Input;

class FinishDemandModal extends React.Component<any> {
  state = {
    visible: false,
    uri: '',
  };

  handleSubmit = (send: any, e: any) => {
    e.preventDefault();
    this.props.form.validateFields(async (err: any, values: any) => {
      if (!err) {
        try {
          await send({
            variables: {
              FinishDemand: { ...values, id: this.props.id },
            },
          });
          message.success('修改成功');
          const { refetch } = this.props;
          refetch();
          this.close();
        } catch (e) {
          message.error(e);
        }
      }
    });
  };

  close = () => {
    const { resetFields } = this.props.form;
    resetFields();
    this.setState({ visible: false, uri: '' });
  };

  render() {
    const { visible } = this.state;
    const { form } = this.props;
    const { getFieldDecorator, getFieldValue } = form;
    return (
      <div>
        <Button
          className={`linear-gradient-gray ${styles.sure}`}
          onClick={() => this.setState({ visible: true })}
          type="primary"
        >
          确认完成
        </Button>
        <Modal
          title="完成需求"
          centered
          visible={visible}
          onCancel={() => this.setState({ visible: false })}
          footer={null}
        >
          <Form>
            <Form.Item label="备注">
              {getFieldDecorator('finish_remark', {
                rules: [{ required: true, message: '请输入备注!' }],
              })(
                <TextArea
                  rows={4}
                  maxLength={100}
                  className="king_normal_text_area"
                />,
              )}
              <span className="form_right">
                {(getFieldValue('finish_remark') &&
                  getFieldValue('finish_remark').length) ||
                  0}
                /100
              </span>
            </Form.Item>
            <Form.Item style={{ textAlign: 'right' }}>
              <Button
                onClick={() => this.setState({ visible: false })}
                style={{ marginRight: '30px' }}
              >
                取消
              </Button>
              <FinishDemandComponent>
                {(send, { loading }) => {
                  return (
                    <Button
                      type="primary"
                      loading={loading}
                      onClick={this.handleSubmit.bind(this, send)}
                    >
                      确定
                    </Button>
                  );
                }}
              </FinishDemandComponent>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

export const FinishDemandModalForm: any = Form.create({
  name: 'create_car',
})(FinishDemandModal);
