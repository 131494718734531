/**
 * 格式化时长
 * @param second
 * @returns {string}
 */
export const realFormatSecond = (second: number | string) => {
  let secondType = typeof second;

  if (secondType === "number" || secondType === "string") {
    second = parseInt(String(second));

    let hours = Math.floor(second / 3600);
    second = second - hours * 3600;
    let mimute = Math.floor(second / 60);
    second = second - mimute * 60;

    return (
      hours + ":" + ("0" + mimute).slice(-2) + ":" + ("0" + second).slice(-2)
    );
  } else {
    return "0:00:00";
  }
};

export const dealNumber = function(money: number | string) {
  if (money && money != null) {
    money = String(money);
    var left = money.split(".")[0];

    var temp = left
      .split("")
      .reverse()
      .join("")
      .match(/(\d{1,3})/g) || ["0"];
    return (
      (Number(money) < 0 ? "-" : "") +
      temp
        .join(",")
        .split("")
        .reverse()
        .join("")
    );
  } else if (money === 0) {
    return "0";
  } else {
    return "";
  }
};

export function getTrueName(name: string) {
  return name.substr(name.lastIndexOf("/") + 1);
}
/*
 * 已知文件路径，获取文件名xxx.doc
 */
export function getFileNameByPath(path: string) {
  var endIndex = path.lastIndexOf("?");
  var index = path.lastIndexOf("/"); // lastIndexOf("/")  找到最后一个  /  的位置
  // var fileName = path.substr(index + 1, endIndex > 0 ? endIndex : path.length); // substr() 截取剩余的字符，即得文件名xxx.doc
  return path.substring(index + 1, endIndex !== -1 ? endIndex : path.length);
}

/*
 * 已知文件路径，获取文件类型doc
 */
export function getFileTypeByPath(path: string) {
  var index = path.lastIndexOf("."); // lastIndexOf("/")  找到最后一个  /  的位置
  var fileType = path.substr(index + 1); // substr() 截取剩余的字符，即文件名doc

  return fileType;
}
/*
 * 已知文件路径，获取文件后缀.doc
 */
export function getFileExtensionByPath(path: string) {
  var index1 = path.lastIndexOf("."); // lastIndexOf("/")  找到最后一个  /  的位置
  var index2 = path.length;
  var fileExtension = path.substr(index1, index2); // substr() 截取剩余的字符，即文件名.doc

  return fileExtension;
}

export const file_types = ["jpg", "png", "jpeg"];

export function getFileType(filePath: string) {
  var startIndex = filePath.lastIndexOf(".");
  var endIndex = filePath.lastIndexOf("?");
  if (startIndex !== -1)
    return filePath
      .substring(startIndex + 1, endIndex !== -1 ? endIndex : filePath.length)
      .toLowerCase();
  else return "";
}

export function getFilesName(state: any[], list?: { fileList: any[] }) {
  if (!list) {
    return undefined;
  }
  let files =
    (list &&
      list.fileList.map((item: any) => {
        const is_item = state.find((uri_item: string) => {
          if (Object.values(uri_item)[0] === item.name) {
            return true;
          }
          return false;
        });
        if (is_item) {
          return Object.keys(is_item)[0];
        }
        return undefined;
      })) ||
    undefined;
  files = files.filter((item) => !!item);
  if (files.length > 0) {
    return JSON.stringify(files);
  }
  return undefined;
}

export function getFileName(name: string) {
  name = name.replace("files/", "");
  let first = name.split("-")[0];
  let second = name.split("-")[1].split(".")[1];
  return first + "." + second;
}
