import React from 'react';
import { Layout, Menu, Icon } from 'antd';
import parse from 'url-parse';
import queryString from 'query-string';

import './base-layout.css';
import { withRouter } from 'react-router';

const { Header, Sider, Content } = Layout;

interface props {
  location: Location;
  history: any;
}

class BaseLayoutComponent extends React.Component<any> {
  state = {
    collapsed: false,
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  onClick = ({ key }: { key: string }) => {
    const url: any = parse(key);
    const params = queryString.parse(url.query);
    const params2 = queryString.parse(this.props.location.search);
    const params3 = queryString.stringify({
      ...params2,
      ...params,
    });
    this.props.history.push(url.pathname + '?' + params3);
  };

  render() {
    const { ContentStyle } = this.props;
    return (
      <Layout id="components-layout-custom-trigger">
        <Sider trigger={null} collapsible collapsed={this.state.collapsed}>
          <div className="logo" />
          <Menu
            theme="dark"
            mode="inline"
            onClick={this.onClick}
            defaultSelectedKeys={[this.props.location.pathname]}
          >
            <Menu.Item key="/designer">
              <Icon type="dingding" />
              <span>设计师管理</span>
            </Menu.Item>
            <Menu.Item key="/demand">
              <Icon type="dingding" />
              <span>需求管理</span>
            </Menu.Item>
            <Menu.Item key="/team">
              <Icon type="user" />
              <span>团队管理</span>
            </Menu.Item>
            <Menu.Item key="/order">
              <Icon type="account-book" />
              <span>订单管理</span>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout>
          <Header style={{ background: '#fff', padding: 0 }}>
            <Icon
              className="trigger"
              type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
              onClick={this.toggle}
            />
          </Header>
          <Content
            style={{
              margin: '24px 16px',
              padding: 24,
              background: '#fff',
              minHeight: 280,
              ...ContentStyle,
            }}
          >
            {this.props.children}
          </Content>
        </Layout>
      </Layout>
    );
  }
}

export const BaseLayout = withRouter(BaseLayoutComponent);
