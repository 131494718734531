import React from 'react';
import {
  Button,
  Modal,
  Form,
  DatePicker,
  TimePicker,
  Popover,
  List,
  Tag,
} from 'antd';

import { ChangeDemandTimeComponent } from '../../../apollo';
import { message } from '../../../components/message';
import moment from 'moment';

const data = [
  {
    title: '待确认',
    id: 1
  },
  {
    title: '进行中',
    id: 2
  },
  {
    title: '等待中',
    id: 3
  },
  {
    title: '已完成',
    id: 4
  },
  {
    title: '已取消',
    id: 5
  },
]

class ChangeDemandModal extends React.Component<any> {
  state = {
    visible: false,
    uri: '',
    status: '',
    popoverVisible: false
  };


  handleSubmit = (send: any, e: any) => {
    e.preventDefault();
    this.props.form.validateFields(async (err: any, values: any) => {
      if (!err) {
        try {
          const date_string = values.finish_date.format("YYYY-MM-DD") + " " + values.finish_date_time.format("HH:mm:ss");
          await send({
            variables: {
              ChangeDemandTime: { status: this.state.status, finish_date: date_string, id: this.props.id },
              // ChangeDemandTime: { finish_date: new Date(values.finish_date.format("YYYY-MM-DD HH:mm")), id: this.props.id },
            },
          });
          message.success('修改成功');
          const { refetch } = this.props;
          refetch();
          this.close();
        } catch (e) {
          message.error(e);
        }
      }
    });
  };

  close = () => {
    const { resetFields } = this.props.form;
    resetFields();
    this.setState({ visible: false, uri: '' });
  };

  change = async (send: any, status: string) => {
    if (status === '进行中') {
      this.setState({
        status,
        visible: true,
        popoverVisible: false
      })
      return
    }
    try {
      await send({
        variables: {
          ChangeDemandTime: { status, id: this.props.id },
          // ChangeDemandTime: { finish_date: new Date(values.finish_date.format("YYYY-MM-DD HH:mm")), id: this.props.id },
        },
      });
      this.setState({
        popoverVisible: false
      })
      message.success('修改成功');
      const { refetch } = this.props;
      refetch();
      this.close();
    } catch (e) {
      message.error(e);
    }
  };
  content = (send: any) => {
    return (< div >
      <List
        size="small"
        dataSource={data}
        renderItem={item => <List.Item
          style={{ cursor: 'pointer', textAlign: 'center', width: '127px' }}
          onClick={this.change.bind(this, send, item.title)}>
          {item.title}
        </List.Item>}
      />
    </div >)

  };
  onclick = () => {
    this.setState({
      popoverVisible: !this.state.popoverVisible
    })
  }
  render() {
    const { visible } = this.state;
    const { form, status } = this.props;
    const { getFieldDecorator } = form;
    return (
      <div style={{ display: 'inline-block' }}>

        <span className="hand" style={{ color: "var(--blue)", marginLeft: '30px' }}>
          <ChangeDemandTimeComponent>
            {(send, { loading }) => {
              return (
                <Popover onVisibleChange={(popoverVisible) => this.setState({ popoverVisible })} visible={this.state.popoverVisible} placement="bottom" content={this.content(send)} trigger="click">
                  <StatusTag status={status} onclick={this.onclick} />
                </Popover>)
            }}
          </ChangeDemandTimeComponent>
        </span>
        <Modal
          title="修改时间"
          centered
          visible={visible}
          onCancel={() => this.setState({ visible: false })}
          footer={null}
        >
          <div>
            现有交付时间：预计{' '}
            {moment(this.props.finish_date).format('YYYY-MM-DD HH:mm')}
          </div>
          <br />
          <Form>
            <Form.Item label="日期" style={{ display: 'flex' }}>
              {getFieldDecorator('finish_date', {
                rules: [{ required: true, message: '请选择日期!' }],
              })(<DatePicker placeholder="选择日期" />)}
            </Form.Item>
            <Form.Item label="时间" style={{ display: 'flex' }}>
              {getFieldDecorator('finish_date_time', {
                rules: [{ required: true, message: '请选择时间!' }],
              })(<TimePicker placeholder="选择时间" defaultOpenValue={moment('00:00:00', 'HH:mm:ss')} />)}
            </Form.Item>
            <p style={{ color: 'rgba(0,0,0,0.3)' }}>
              注：不可以选择周六周日以及法定节假日
              {/* <br />
              一旦选择修改后，其他需求时间也将相应发生变化 */}
            </p>
            <Form.Item style={{ textAlign: 'right' }}>
              <Button
                onClick={() => this.setState({ visible: false })}
                style={{ marginRight: '30px' }}
              >
                取消
              </Button>
              <ChangeDemandTimeComponent>
                {(send, { loading }) => {
                  return (
                    <Button
                      type="primary"
                      loading={loading}
                      onClick={this.handleSubmit.bind(this, send)}
                    >
                      确定
                    </Button>
                  );
                }}
              </ChangeDemandTimeComponent>
            </Form.Item>
          </Form>
        </Modal>
      </div >
    );
  }
}

export const ChangeDemandModalForm: any = Form.create({
  name: 'change-demand-time-modal-form',
})(ChangeDemandModal);

export function StatusTag({ status, onclick }: { status: string, onclick: () => void }) {
  let tagStyle = {
    background: '#F8FFF1',
    border: '1px solid #8CCC4E',
    borderRadius: '4px',
    fontFamily: 'PingFang SC',
    // fontStyle: 'normal',
    // fontWeight: 400,
    // fontSize: '14px',
    // lineHeight: '14px',
    color: '#8CCC4E',
    // width: '65px',
    // height: '25px'
  }
  let text = status;
  if (status === '等待中') {
    tagStyle.background = '#FFF8EA'
    tagStyle.border = '1px solid #DEBB6E'
    tagStyle.color = '#DEBB6E'
  }
  if (status === '待确认') {
    tagStyle.background = '#FFF2F1'
    tagStyle.border = '1px solid #F67369'
    tagStyle.color = '#F67369'
  }
  if (status === '已完成') {
    // tagStyle.background = '#FFF8EA'
    // tagStyle.border = '1px solid #DEBB6E'
    // tagStyle.color = '#DEBB6E'
  }
  if (status === '已取消') {
    tagStyle.background = '#F7F8FA'
    tagStyle.border = '1px solid #9FA8B4'
    tagStyle.color = '#9FA8B4'
  }
  if (status === '进行中') {
    // tagStyle.background = '#FFF8EA'
    // tagStyle.border = '1px solid #DEBB6E'
    // tagStyle.color = '#DEBB6E'
  }
  // if ((status === "进行中" || status === '等待中' ) && team_status === "待付款" && type !== "独立项目") {
  //   tagStyle.background = '#FFF2F1'
  //   tagStyle.border = '1px solid #F67369'
  //   tagStyle.color = '#F67369'
  //   text = team_status
  // } else if (team_status === "待续费" || team_status === "待付款") {
  //   tagStyle.background = '#FFF2F1'
  //   tagStyle.border = '1px solid #F67369'
  //   tagStyle.color = '#F67369'
  //   text = team_status
  // }

  return <Tag
    onClick={onclick}
    style={
      {
        ...tagStyle
      }
    }>{text}</Tag>
}
