import React from 'react';
import { Button, Icon, Modal, Form, Upload } from 'antd';

import { CreateAnnexComponent } from '../../apollo';
import { message } from '../../components/message';
// import styles from './index.module.css';
import { up_load_life_photo_options } from '../../lib/upload.client';
import bluebird from 'bluebird'
import { v4 as uuid } from 'uuid'
import { getFileType } from '../photo';

const { Dragger } = Upload;

class UploadImageModal extends React.Component<any> {
  state = {
    visible: false,
    uri: [],
  };

  handleSubmit = async (send: any, e: any) => {
    e.preventDefault();
    if (this.state.uri.length === 0) {
      return message.error(new Error('请上传设计稿'));
    }
    try {
      const date = new Date();
      let first_result: any;
      const group_id = uuid();
      await bluebird.each(this.state.uri, async (path: any, index) => {
        const result = await send({
          variables: {
            CreateAnnex: {
              demand_id: this.props.demand_id,
              path: path.uri,
              file_old_name: path.file_old_name,
              group_id,
              group_date: date
            }
          },
        });
        if (!first_result && ["jpg", "png", "jpeg"].includes(getFileType(path.uri))) {
          first_result = result;
        }
      })
      message.success('上传成功');
      const { refetch, callback } = this.props;
      refetch();
      callback(first_result);
      this.close();
    } catch (e) {
      message.error(e);
    }
  };

  close = () => {
    const { resetFields } = this.props.form;
    resetFields();
    this.setState({ visible: false, uri: '' });
  };

  render() {
    const { visible } = this.state;
    return (
      <div style={{ display: 'inline-block', marginRight: '12px' }}>
        <Button onClick={() => {
          this.setState({ visible: true })
        }}>
          上传设计稿
        </Button>
        <CreateAnnexComponent>
          {(send, { loading }) => {
            return (
              <Modal
                title="上传设计稿"
                centered
                visible={visible}
                onCancel={() => this.setState({ visible: false, uri: '' })}
                onOk={this.handleSubmit.bind(this, send)}
                confirmLoading={loading}
                okText="上传"
                cancelText="取消"
              >
                {visible && <Dragger
                  // disabled={!!uri}
                  multiple
                  {...up_load_life_photo_options((uri, file) =>
                    this.setState({
                      // uri: file.name,
                      uri: [...this.state.uri, { uri, file_old_name: file.name }],
                    }),
                  )}
                // accept=".pdf,.jpg,.word,.rar,.zip"
                >
                  <p className="ant-upload-drag-icon">
                    <Icon type="inbox" />
                  </p>
                  <p className="ant-upload-text">点击或者拖动到此处上传</p>
                  <p className="ant-upload-hint">
                    支持jpg/jpeg/png/pdf/psd/ai/sketch格式
                    <br />
                    支持批量上传
                  </p>
                </Dragger>}
              </Modal>
            );
          }}
        </CreateAnnexComponent>
      </div >
    );
  }
}

export const UploadImageModalForm: any = Form.create({ name: 'create_car' })(
  UploadImageModal,
);
